import React, { Component } from 'react';
import StyledSVG from "../assets/svg";
import { socialLinks } from '../data/data';

export default class Contact extends Component {
    render() {
        return (
            <section class="contact section" id="contact">
                <h2 class="tagline">CONTACT</h2>
                <p class="description">Feel free to contact me using one of the methods below!</p>
                <div class="contact-container">
                    {
                        socialLinks.map(({icon, label, url}) => (
                            <span class="social">
                                <a class="social-link" href={url} target="_blank">
                                    <div class="social-logo"><StyledSVG logo={icon}/></div>
                                    <div class="social-label">{`${label}`}</div>
                                </a>
                            </span>
                        ))
                    }
                </div>
            </section>
        )
    }
}