import React, { Component } from 'react';
import StyledSVG from "../assets/svg";
import { tools } from "../data/data";

export default class TechStack extends Component {
    render() {
        return (
            <section class="tech-stack section" id="tech-stack">
                <h2 class="tagline">TOOLS</h2>
                <div class="tools-container">
                    {
                        tools.map(({ icon, label }) => (
                            <span class="tech_tool">
                                <StyledSVG logo={icon}/>
                                <span>{`${label}`}</span>
                            </span>
                        ))
                    }
                </div>
            </section>
        );
    }
}