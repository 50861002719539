import React, { Component } from 'react';
import {socialLinks, tools} from "../data/data";
import StyledSVG from "../assets/svg";

export default class Home extends Component {
    render() {
        return (
            <section class="home section" id="home">
                <div class="home-container">
                    <h1 class="headline">CHASE WATSON</h1>
                    <p class="description">Software Engineer.</p>
                    <hr/>
                </div>
                <div className="contact-container">
                    {
                        socialLinks.map(({icon, label, url}) => (
                            <span className="social">
                                <a className="social-link" href={url} target="_blank">
                                    <div className="social-logo"><StyledSVG logo={icon}/></div>
                                </a>
                            </span>
                        ))
                    }
                </div>
            </section>
            
        );
    }
}