import React, { Component } from 'react';

export default class NavBar extends Component {
    render() {
        return (
            <div>
                <nav id="nav-wrap">
                    <ul id="nav">
                        <li class="current"><a class="smoothscroll" href="#home">Home</a></li>
                        <li><a class="smoothscroll"  href="#about">About</a></li>
                        <li><a class="smoothscroll"  href="#tech-stack">Tools</a></li>
                        <li><a class="smoothscroll"  href="#projects">Projects</a></li>
                        <li><a class="smoothscroll"  href="#resume">Resume</a></li>
                        <li><a class="smoothscroll"  href="#contact">Contact</a></li>
                    </ul>
                </nav>
            </div>
        )
    }
}