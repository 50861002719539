export const tools = [
    {
        label: 'Java',
        icon: 'java'
    },
    {
        label: 'NodeJS',
        icon: 'nodejs'
    },
    {
        label: 'Firebase',
        icon: 'firebase'
    },
    {
        label: 'OAuth',
        icon: 'oauth'
    },
    {
        label: 'OpenID',
        icon: 'openid'
    },
    {
        label: 'GitHub',
        icon: 'github'
    },
];

export const socialLinks = [
    {
        label: 'Resume',
        url: 'https://drive.google.com/file/d/1glWJXCqUzh9vVKHE-z78jHK_kuAzInhu/view',
        icon: 'resume'
    },
    {
        label: 'Email',
        url: 'mailto:chase@w-ac.com',
        icon: 'email'
    },
    {
        label: 'GitHub',
        url: 'https://github.com/w-ac',
        icon: 'github'
    },
    {
        label: 'LinkedIn',
        url: 'https://linkedin.com/in/w-ac',
        icon: 'linkedin'
    }
];
