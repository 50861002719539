import React, { Component } from 'react';
import TechStack from './components/TechStack';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';
import './assets/css/layout.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Home/>
      </div>
    );
  }
}

export default App;
