import styled, {css} from 'styled-components';

import java from '../assets/icons/java.svg';
import oauth from '../assets/icons/oauth.svg';
import firebase from '../assets/icons/firebase.svg';
import netlify from '../assets/icons/netlify.png';
import openid from '../assets/icons/openid.svg';
import nodejs from '../assets/icons/nodejs.svg';
import linkedin from '../assets/icons/linkedin.svg';
import github from '../assets/icons/github.svg';
import email from '../assets/icons/email.svg';
import resume from '../assets/icons/resume.svg';

const svgProps = {
    linkedin: Boolean,
    twitter: Boolean,
    codepen: Boolean,
    github: Boolean,
    logo: String
}

const StyledSVG = styled('span', svgProps)`
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 24px;
    ${props => props.logo === "java" && css`
        background-image: url(${java});
        background-size: 28px 28px;`
    }
    ${props => props.logo === "nodejs" && css`
        background-image: url(${nodejs});
        background-size: 28px 28px;
    `}
    ${props => props.logo === "oauth" && css`
        background-image: url(${oauth})
    `}
    ${props => props.logo === "firebase" && css`
        background-image: url(${firebase});
        background-size: 28px 28px;
    `}
    ${props => props.logo === "netlify" && css`
        background-image: url(${netlify});
        background-size: 28px 28px;
    `}
    ${props => props.logo === "openid" && css`
        background-image: url(${openid});
        background-size: 28px 28px;
    `}
    ${props => props.logo === "github" && css`
        background-image: url(${github});
        background-size: 28px 28px;    
    `}
    ${props => props.logo === "linkedin" && css`
        background-image: url(${linkedin});
        background-size: 28px 28px;    
    `}
    ${props => props.logo === "email" && css`
        background-image: url(${email});
        background-size: 28px 28px;    
    `}
    ${props => props.logo === "resume" && css`
        background-image: url(${resume});
        background-size: 28px 28px;    
    `}
`;

export default StyledSVG