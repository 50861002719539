import React, { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <section class="about section" id="about">
                <div class="about-container">
                    <h2 class="headline">Hi</h2>
                    <p class="description">
                        I’m Chase. Currently, I am based in Charlotte, working as a Full-Stack Engineer at Paymentus. 
                        I am a full-stack developer and creator of desktop, web, & mobile solutions with a focus on back-end development.
                    </p>
                    <p class="description">
                        In my spare time, I enjoy experimenting with new tech, mountain biking, hiking, kayaking, and photography. 
                        I’m always interested in new interesting projects, so feel free to shoot me a message.
                    </p>
                </div>
            </section>
        )
    }
}